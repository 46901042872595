import { IUiState } from '../types'

export const initialState: IUiState = {
  environment: '',
  initializationStatus: {
    loading: false,
    error: null,
    loaded: false,
  },
}
