import { IUserState } from './types'

export const userActionTypes = {
  FETCH_USER_ORGENHET: <const>'user/FETCH_USER_ORGENHET',
  FETCH_USER_ORGENHET_FAIL: <const>'user/FETCH_USER_ORGENHET_FAIL',
  FETCH_USER_ORGENHET_OK: <const>'user/FETCH_USER_ORGENHET_OK',
  FETCH_USER_AVDELING_FAIL: <const>'user/FETCH_USER_AVDELING_FAIL',
  FETCH_USER_AVDELING_OK: <const>'user/FETCH_USER_AVDELING_OK',
  FETCH_USER_REGION_OK: <const>'user/FETCH_USER_REGION_OK',
  FETCH_USER_REGION_FAIL: <const>'user/FETCH_USER_REGION_FAIL',
  SET_USER: <const>'user/SET_USER',
  GET_USER: <const>'user/GET_USER',
  UPDATE_TOKEN: <const>'user/UPDATE_TOKEN',
  USER_EXPIRED: <const>'user/USER_EXPIRED',
  USER_SIGNED_OUT: <const>'user/USER_SIGNED_OUT',
}

export const userActions = {
  fetchUserOrgenhet: () => ({
    type: userActionTypes.FETCH_USER_ORGENHET,
  }),

  setUser: (username: string, name: string, title?: string) => ({
    type: userActionTypes.SET_USER,
    username,
    name,
    title,
  }),
  getUser: () => ({
    type: userActionTypes.GET_USER,
  }),
  updateToken: (accessToken: string) => ({
    type: userActionTypes.UPDATE_TOKEN,
    data: accessToken,
  }),
  userExpired: () => ({
    type: userActionTypes.USER_EXPIRED,
  }),
  userSignedOut: () => ({
    type: userActionTypes.USER_SIGNED_OUT,
  }),

  fetchUserAvdelingFail: (err: string) => ({
    type: userActionTypes.FETCH_USER_AVDELING_FAIL,
    err,
  }),

  fetchUserAvdelingOk: (data: IUserState['avdeling']) => ({
    type: userActionTypes.FETCH_USER_AVDELING_OK,
    data,
  }),

  fetchUserOrgenhetFail: (err: string) => ({
    type: userActionTypes.FETCH_USER_ORGENHET_FAIL,
    err,
  }),

  fetchUserOrgenhetOk: (data: IUserState['me']) => ({
    type: userActionTypes.FETCH_USER_ORGENHET_OK,
    data,
  }),

  fetchUserRegionFail: (err: string) => ({
    type: userActionTypes.FETCH_USER_REGION_FAIL,
    err,
  }),

  fetchUserRegionOk: (data: IUserState['region']) => ({
    type: userActionTypes.FETCH_USER_REGION_OK,
    data,
  }),
}
