import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Tilsynsobjektinfo } from '@mattilsynet/tilsynsobjektinfo-komponent'
import { useTypedSelector } from '../../common/custom-hooks'
import { userSelectors } from '../../ducks/user/selectors'
import { CARDS, getVisibleCards } from '../../common/get-visible-cards'
import { Column, Text, ThemeContext } from '@mattilsynet/mt-ui'

const NoAccessLandingPage = () => {
  const theme = useContext(ThemeContext)
  return (
    <Column padding={[15, 4, 4, 4]} spacing={1} align="center">
      <Text size="heading2" color={theme.gray2}>
        Du må ha en lenke til et tilsynsobjekt
      </Text>
    </Column>
  )
}

const TilsynsobjektinfoRoute = () => {
  const { tilsynsobjektId } = useParams()
  const authUser = useTypedSelector(userSelectors.getAuthUser)
  const accessToken = authUser?.accessToken

  const visibleCards = getVisibleCards(accessToken).filter(
    (card) => card !== CARDS.MKI, // TODO: fjern når MKI kan vises uten eftanummer
  )

  if (!tilsynsobjektId) {
    return <NoAccessLandingPage />
  }

  return (
    <Tilsynsobjektinfo
      tilsynsobjektId={tilsynsobjektId}
      visibleCards={visibleCards}
    />
  )
}

const HomeRoute = () => TilsynsobjektinfoRoute()

export default HomeRoute
