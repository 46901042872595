import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import { map } from 'rxjs'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { ThemeProvider } from '@mattilsynet/mt-ui'
import locale from './common/nb-locale-dayjs'
import { themePicker } from './common/theme'
import store from './reducers/store'
import { useTypedSelector } from './common/custom-hooks'
import '@mattilsynet/mt-ui/dist/assets/global.css'
import './style.css'
import { uiActions } from './ducks/ui/actions'
import { IUiState } from './ducks/ui/types'
import { config$ } from './config'
import './common/elastic-logging'
import { router } from './routes/router'

dayjs.extend(isBetween)
dayjs.locale(locale)
dayjs.extend(customParseFormat)

config$.pipe(map(({ environment }) => environment)).subscribe((env) => {
  store.dispatch(uiActions.setEnvironment(env as IUiState['environment']))
})

const Routes = () => {
  const environment = useTypedSelector((state) => state.ui.environment)
  const theme = themePicker(environment)

  return (
    <ThemeProvider value={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

const App = () => (
  <Provider store={store}>
    <Routes />
  </Provider>
)

export default App
