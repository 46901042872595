import * as R from 'ramda'
import { commonRedux } from '@mattilsynet/mt-common'
import { initialState } from './initialState'
import { uiActionTypes } from '../actions'
import { IUiActions, IUiState } from '../types'
import { reducerLenses } from '../selectors'

export const STORE_NAME = 'ui'

const uiReducer = commonRedux.createReducer<IUiState, IUiActions>(
  initialState,
  {
    [uiActionTypes.ACCESS_DENIED]: (state: IUiState): IUiState =>
      R.set(
        reducerLenses.initializationStatus(),
        {
          loading: false,
          error: null,
          loaded: true,
        },
        state,
      ),

    [uiActionTypes.INITIALIZE]: (state: IUiState): IUiState =>
      R.set(
        reducerLenses.initializationStatus(),
        {
          loading: true,
          error: null,
          loaded: false,
        },
        state,
      ),

    [uiActionTypes.INITIALIZE_SUCCESS]: (state: IUiState): IUiState =>
      R.set(
        reducerLenses.initializationStatus(),
        {
          loading: false,
          error: null,
          loaded: true,
        },
        state,
      ),

    [uiActionTypes.INITIALIZE_FAILED]: (state: IUiState, { error }): IUiState =>
      R.set(
        reducerLenses.initializationStatus(),
        {
          loading: false,
          error: error,
          loaded: false,
        },
        state,
      ),

    [uiActionTypes.SET_ENVIRONMENT]: (
      state: IUiState,
      { environment }: { environment: IUiState['environment'] },
    ): IUiState => ({ ...state, environment }),
  },
)

export default {
  [STORE_NAME]: uiReducer,
}
