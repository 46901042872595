import { ReplaySubject, combineLatest } from 'rxjs'
import { config$ } from '../config'
import { map } from 'rxjs/operators'
import Keycloak from '@mattilsynet/mt-keycloak'

export const authSubject = new ReplaySubject<Keycloak>(1)

combineLatest(config$)
  .pipe(
    map(([config]) => {
      return {
        url: config['keycloak.url'],
        realm: config['keycloak.realm'],
        clientId: config['keycloak.clientId'],
        idpHint: config['keycloak.idpHint'],
        saveTokensToStorage: true,
      }
    }),
  )
  .subscribe((settings) => {
    authSubject.next(new Keycloak(settings))
  })
