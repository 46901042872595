import * as R from 'ramda'
import { initialState } from './initialState'
import { userActionTypes } from '../actions'
import { reducerLenses } from '../selectors'
import { IUserActions, IUserState } from '../types'
import { commonRedux } from '@mattilsynet/mt-common'

export const STORE_NAME = 'user'

const userReducer = commonRedux.createReducer<IUserState, IUserActions>(
  initialState,
  {
    [userActionTypes.FETCH_USER_AVDELING_OK]: (
      state: IUserState,
      { data }: any,
    ) => R.set(reducerLenses.avdeling(), R.omit(['children'], data), state),
    [userActionTypes.FETCH_USER_REGION_OK]: (
      state: IUserState,
      { data }: any,
    ) => R.set(reducerLenses.region(), R.omit(['children'], data), state),
    [userActionTypes.FETCH_USER_ORGENHET_OK]: (
      state: IUserState,
      { data }: any,
    ) => R.set(reducerLenses.me(), R.omit(['children'], data), state),

    [userActionTypes.SET_USER]: (
      state: IUserState,
      {
        username,
        name,
        title,
      }: { username: string; name: string; title?: string },
    ) => ({
      ...state,
      authUser: {
        ...(state.authUser || {}),
        username,
        name,
        title,
      },
    }),

    [userActionTypes.UPDATE_TOKEN]: (
      state: IUserState,
      { data }: { data: string },
    ) => ({
      ...state,
      authUser: {
        ...(state.authUser || {}),
        accessToken: data,
      },
    }),
  },
)

export default {
  [STORE_NAME]: userReducer,
}
