import React from 'react'
import { LoadingSpinner, Grid, GridItem, Row } from '@mattilsynet/mt-ui'

interface IPageLoadingProps {
  loadingText: string
}

export const PageLoading = ({ loadingText }: IPageLoadingProps) => {
  return (
    <Grid>
      <GridItem>
        <Row minHeight="71vh" center>
          <LoadingSpinner title={loadingText} />
        </Row>
      </GridItem>
    </Grid>
  )
}
