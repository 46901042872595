import React, { ReactElement } from 'react'
import { useTypedSelector } from '../common/custom-hooks'
import { IStoreState } from '../reducers/types'
import { Login } from '../components/login'

export const Private = ({ element }: { element: ReactElement }) => {
  const username = useTypedSelector(
    (state: IStoreState) => state.user.authUser?.username,
  )

  if (!username) {
    return <Login />
  }

  return element
}
