import React, { useEffect, useState } from 'react'
import { AboutPage } from '@mattilsynet/mt-ui'
import buildInfo from '../../../package.json'
import { useGoBack } from '../../common/navigation'

const About = () => {
  const [apiStatus, setApiStatus] = useState({ checked: false, ok: false })

  const goBack = useGoBack()

  useEffect(() => {
    fetch('/api')
      .then((cb) => {
        if (cb.ok) {
          setApiStatus({
            checked: true,
            ok: true,
          })
        }
      })
      .catch(() => {
        setApiStatus({
          checked: true,
          ok: false,
        })
      })
  }, [])

  return (
    <AboutPage
      pageUrl="https://makuba.mattilsynet.no/"
      appName="Tilsynsobjektinfo"
      description=""
      onGoBackClick={goBack}
      appVersion={buildInfo.version}
      statusList={[
        { type: 'Server', checked: apiStatus.checked, ok: apiStatus.ok },
      ]}
    />
  )
}

export default About
