import { combineEpics } from 'redux-observable'
import { commonApi } from '../api'
import { apm } from '@elastic/apm-rum'
import { catchError } from 'rxjs/operators'
import { tilsynsobjektinfo } from '@mattilsynet/tilsynsobjektinfo-komponent'
import uiEpic from '../ducks/ui/epic'
import userEpic from '../ducks/user/epic'

const authTokenPath = ['user', 'authUser', 'accessToken']

export const API_ROUTES = {
  ARKIV: '/api/arkiv-api',
  BILDE: '/api/bilde-api',
  KJOETTKONTROLL_FUNN: '/api/kjoettkontroll-funn-api',
  KODEVERK: '/api/kodeverk-api/kodeverk',
  MELDING_TIL_LOKALT_MATTILSYN: '/api/melding-til-lokalt-mattilsyn-api',
  NOTIFICATION: '/api/notification-api',
  ORGENHET: '/api/orgenhet-api',
  TILSYNSOBJEKT: '/api/tilsynsobjekt-api',
}

const rootEpic = (action$, store$, dependencies) =>
  combineEpics(
    tilsynsobjektinfo.epics(commonApi as any, authTokenPath) as any,
    uiEpic(),
    userEpic(commonApi),
  )(action$, store$, dependencies).pipe(
    catchError((err, source) => {
      // eslint-disable-next-line no-console
      console.error('catched', err)
      apm.captureError(err)
      return source
    }),
  )

export default rootEpic
