// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#login {
  padding: 1em;
}

#login .content {
  height: 15em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#login .content .Button {
  width: 10em;
}

#login .content > div {
  padding: 0.5em;
}

#login .content .text {
  font-size: 1.3em;
}
`, "",{"version":3,"sources":["webpack://./src/components/login/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["#login {\n  padding: 1em;\n}\n\n#login .content {\n  height: 15em;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n#login .content .Button {\n  width: 10em;\n}\n\n#login .content > div {\n  padding: 0.5em;\n}\n\n#login .content .text {\n  font-size: 1.3em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
