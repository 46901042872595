import React from 'react'
import { ImageView } from '@mattilsynet/tilsynsobjektinfo-komponent'
import { useParams } from 'react-router-dom'
import { useGoBack } from '../../common/navigation'

export const ImageViewRoute = () => {
  const { imageId } = useParams()

  const goBack = useGoBack()

  return <ImageView imageId={imageId} goBack={goBack} />
}
