import React from 'react'
import { AppHeader } from '@mattilsynet/mt-ui'
import { Outlet } from 'react-router-dom'
import { Toast } from '@mattilsynet/mt-common'
import TopMenuElements from '../components/top-menu-elements'
import { useTypedSelector } from '../common/custom-hooks'

export const Layout = () => {
  const environment = useTypedSelector((state) => state.ui.environment)

  return (
    <>
      <AppHeader
        appName="Tilsynsobjektinfo"
        env={environment}
        right={<TopMenuElements />}
      />
      <Outlet />
      <Toast.FixedToastArea id="common-toastarea" />
    </>
  )
}
