import { colors } from '@mattilsynet/mt-ui'

export const themePicker = (env: string) => {
  switch (env) {
    case 'test':
      return colors.testTheme
    default:
      return colors.defaultTheme
  }
}
