import { IUiState } from './types'

export const uiActionTypes = {
  ACCESS_DENIED: <const>'ui/ACCESS_DENIED',
  INITIALIZE: <const>'ui/INITIALIZE',
  INITIALIZE_FAILED: <const>'ui/INITIALIZE_FAILED',
  INITIALIZE_SUCCESS: <const>'ui/INITIALIZE_SUCCESS',
  SET_PAGE_TITLE: <const>'ui/SET_PAGE_TITLE',
  SET_ENVIRONMENT: <const>'ui/SET_ENVIRONMENT',
}

export const uiActions = {
  setEnvironment: (environment: IUiState['environment']) => ({
    type: uiActionTypes.SET_ENVIRONMENT,
    environment,
  }),

  accessDenied: () => ({
    type: uiActionTypes.ACCESS_DENIED,
  }),

  initialize: () => ({ type: uiActionTypes.INITIALIZE }),
  initializeSuccess: () => ({ type: uiActionTypes.INITIALIZE_SUCCESS }),
  initializeFailed: (error) => ({
    type: uiActionTypes.INITIALIZE_FAILED,
    error,
  }),
}
