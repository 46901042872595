import { jwtDecode } from 'jwt-decode'

export enum CARDS {
  MKI = 'mki',
  PROVEANALYSESVAR = 'proveanalysesvar',
  RESTRIKSJONER = 'restriksjoner',
  MELDINGER = 'meldinger',
  AM_PM_HISTORIKK = 'am-pm-historikk',
  PRODUKSJONSFORMER = 'produksjonsformer',
  DETAILS = 'details',
  VIRKSOMHET = 'virksomhet',
  USR = 'usr',
  TILSYNSKVITTERING = 'tilsynskvittering',
  FAKTABESKRIVELSER_TIDLIGERE_TILSYN = 'faktabeskrivelser-tidligere-tilsyn',
}

const CARDS_WITH_RESOURCE_ACCESSES = {
  [CARDS.MKI]: ['mki-api'],
  [CARDS.PROVEANALYSESVAR]: ['tilsynsobjekt-api'],
  [CARDS.RESTRIKSJONER]: ['tilsynsobjekt-api'],
  [CARDS.MELDINGER]: [
    'melding-til-lokalt-mattilsyn-api',
    'kjoettkontroll-funn-api',
    'virksomhet-api',
  ],
  [CARDS.AM_PM_HISTORIKK]: ['kjoettkontroll-funn-api'],
  [CARDS.PRODUKSJONSFORMER]: [],
  [CARDS.DETAILS]: [],
  [CARDS.VIRKSOMHET]: ['virksomhet-api'],
  [CARDS.USR]: [],
  [CARDS.TILSYNSKVITTERING]: ['tilsynskvittering-api'],
  [CARDS.FAKTABESKRIVELSER_TIDLIGERE_TILSYN]: ['tilsynsobjektinfo-api'],
}

export const getVisibleCards = (accessToken?: string): string[] => {
  if (!accessToken) return []

  const accessTokenData = jwtDecode(accessToken) as Record<string, any>
  const resourceAccess = accessTokenData.resource_access

  return Object.values(CARDS).filter((card: string) => {
    const cardResources = CARDS_WITH_RESOURCE_ACCESSES[card]
    return cardResources.every((r) => !!resourceAccess[r])
  })
}
