import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, LoadingSpinner } from '@mattilsynet/mt-ui'
import { authSubject } from '../../auth/keycloak'
import { userActions } from '../../ducks/user/actions'
import { PageLoading } from '../page-loading'
import './style.css'
import { useTOINavigate } from '../../common/navigation'
import { useLocation } from 'react-router'

const excludedPaths =
  /^\/login|^\/logout|^\/setup|^\/access-denied|^\/forbered-new-tilsyn/

const pathIfValid = (path: string | null): string =>
  !path || excludedPaths.test(path) ? '/' : path

export const Login = () => {
  const navigate = useTOINavigate()
  const dispatch = useDispatch()
  const isOnline = window.navigator.onLine
  const { pathname, search } = useLocation()
  const pathParam = pathIfValid(
    new URLSearchParams(location.search).get('path'),
  )
  const path =
    pathParam.length > 1 ? pathParam : pathIfValid(pathname.concat(search))

  useEffect(() => {
    authSubject.subscribe((keycloak) => {
      keycloak.addListener('logout', () => navigate('/logout/callback'))

      return keycloak
        .init({ expectedRole: 'tilsynsobjektinfo-user' })
        .then(({ isAuthenticated, hasRealmAccess }) => {
          if (isAuthenticated && hasRealmAccess) {
            return keycloak.loadUserProfile().then((profile) => {
              dispatch(
                userActions.setUser(
                  profile.username,
                  `${profile.firstName} ${profile.lastName} `,
                  'N/A',
                ),
              )
              navigate(path)
            })
          } else if (isAuthenticated) {
            navigate('/access-denied')
          } else if (isOnline) {
            keycloak.redirectToLogin(
              `${window.location.origin}/login/callback?path=${path}`,
            )
          } else {
            navigate('/offline-login')
          }
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <PageLoading loadingText="Logger inn" />
}

export const Logout = () => {
  useEffect(() => {
    authSubject.subscribe((keycloak) => {
      return keycloak.logout('logout/callback')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <LoadingSpinner title="Logger ut" />
}

export const LogoutCallback = () => {
  const navigate = useTOINavigate()

  return (
    <div id="login">
      <div className="content">
        <div className="text">Du er ikke logget inn.</div>
        <Button onClick={() => navigate('/login')}>Logg inn</Button>
      </div>
    </div>
  )
}

export const LogoutView = () => {
  return <PageLoading loadingText="Logger ut" />
}
