import React from 'react'
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import { Layout } from './layout'
import { Private } from './private'
import About from './about'
import { ImageViewRoute } from './image-view'
import NoRoute from './no-route'
import HomeRoute from './home'
import { Login, Logout, LogoutCallback } from '../components/login'
import AccessDeniedRoute from './access-denied'

export { router }

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route element={<NoRoute />} />
      <Route path="/about" element={<Private element={<About />} />} />
      <Route
        path="/image-view/:id"
        element={<Private element={<ImageViewRoute />} />}
      />
      <Route path="/" element={<Private element={<HomeRoute />} />} />
      <Route
        path="/:tilsynsobjektId?"
        element={<Private element={<HomeRoute />} />}
      />

      <Route path="/login" element={<Login />} />
      <Route path="/login/callback" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/logout/callback" element={<LogoutCallback />} />
      <Route path="/access-denied" element={<AccessDeniedRoute />} />
    </Route>,
  ),
)
