// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserMenu .menu {
  margin: 0.3em;
  padding: 0.7em;
}
`, "",{"version":3,"sources":["webpack://./src/components/user-menu/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;AAChB","sourcesContent":[".UserMenu .menu {\n  margin: 0.3em;\n  padding: 0.7em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
