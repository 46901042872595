import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
  AnyAction,
} from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { IStoreState } from './types'
import epics from '../epics'
import { tilsynsobjektinfo } from '@mattilsynet/tilsynsobjektinfo-komponent'
import uiReducer from '../ducks/ui/reducer'
import userReducer from '../ducks/user/reducer'
import { authSubject } from '../auth/keycloak'
import { userActions } from '../ducks/user/actions'

const epicMiddleware = createEpicMiddleware<
  AnyAction,
  AnyAction,
  IStoreState,
  void
>()

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancers = composeEnhancers(applyMiddleware(epicMiddleware))

export const reducers = {
  ...tilsynsobjektinfo.reducer,
  ...uiReducer,
  ...userReducer,
}

export const rootReducer = combineReducers(reducers)

const store: Store<IStoreState> = createStore(rootReducer, enhancers)

// Sync token with redux-store
authSubject.subscribe((keycloak) => {
  keycloak.addListener('authSuccess', ({ accessToken }) => {
    store.dispatch(userActions.updateToken(accessToken))
  })

  keycloak.addListener('refreshSuccess', ({ accessToken }) => {
    store.dispatch(userActions.updateToken(accessToken))
  })

  keycloak.addListener('refreshError', () => {
    store.dispatch(userActions.userExpired())
  })

  keycloak.addListener('logout', () => {
    store.dispatch(userActions.userSignedOut())
  })

  keycloak.addListener('tokenExpired', () => {
    keycloak.updateToken()
  })
})

epicMiddleware.run(epics)

export default store
