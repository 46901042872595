// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', Areal, sans-serif;
  font-size: 16px;
  background-color: #f2f2f2;
  color: #222222;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.page-content {
  padding: 1.25em 1.25em 1.25em 1.25em;
  display: grid;
  grid: auto-flow min-content / repeat(12, 1fr);
  grid-gap: 1.25em;
  min-height: 80vh;
  max-width: 88.5em;
  margin: 0 auto;
  min-width: 0;
}

.page-content > main {
  display: grid;
  grid: inherit;
  grid-gap: inherit;
}

.page-content > *,
.page-content > main > * {
  grid-column: 1 / -1;
  min-height: 0;
  min-width: 0;
}

.ModalWrapper .modal-child {
  max-width: 50em;
}

.ModalWrapper .modal-body > * {
  margin-bottom: 1.25rem;
}

#ErrorBox,
#LoadingSpinner {
  height: 10em;
}

#LoadingSpinner .TailSpin {
  max-width: 8em;
  max-height: 8em;
}

@media screen and (max-width: 1023px) {
  .page-content {
    grid: auto-flow min-content / repeat(8, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .page-content {
    padding: 0.625em 0.625em 5em 0.625em;
    grid: auto-flow min-content / repeat(4, 1fr);
    grid-gap: 0.625em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,UAAU;EACV,iDAAiD;EACjD,eAAe;EACf,yBAAyB;EACzB,cAAc;AAChB;;AAEA;;;;;;EAME,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;EACpC,aAAa;EACb,6CAA6C;EAC7C,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;EACb,aAAa;EACb,iBAAiB;AACnB;;AAEA;;EAEE,mBAAmB;EACnB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE;IACE,oCAAoC;IACpC,4CAA4C;IAC5C,iBAAiB;EACnB;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700');\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: 'Source Sans Pro', Areal, sans-serif;\n  font-size: 16px;\n  background-color: #f2f2f2;\n  color: #222222;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  font-weight: bold;\n}\n\n.page-content {\n  padding: 1.25em 1.25em 1.25em 1.25em;\n  display: grid;\n  grid: auto-flow min-content / repeat(12, 1fr);\n  grid-gap: 1.25em;\n  min-height: 80vh;\n  max-width: 88.5em;\n  margin: 0 auto;\n  min-width: 0;\n}\n\n.page-content > main {\n  display: grid;\n  grid: inherit;\n  grid-gap: inherit;\n}\n\n.page-content > *,\n.page-content > main > * {\n  grid-column: 1 / -1;\n  min-height: 0;\n  min-width: 0;\n}\n\n.ModalWrapper .modal-child {\n  max-width: 50em;\n}\n\n.ModalWrapper .modal-body > * {\n  margin-bottom: 1.25rem;\n}\n\n#ErrorBox,\n#LoadingSpinner {\n  height: 10em;\n}\n\n#LoadingSpinner .TailSpin {\n  max-width: 8em;\n  max-height: 8em;\n}\n\n@media screen and (max-width: 1023px) {\n  .page-content {\n    grid: auto-flow min-content / repeat(8, 1fr);\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .page-content {\n    padding: 0.625em 0.625em 5em 0.625em;\n    grid: auto-flow min-content / repeat(4, 1fr);\n    grid-gap: 0.625em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
