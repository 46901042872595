// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopMenuElements {
  display: flex;
  justify-content: space-between;
}

.TopMenuElements .NotificationWrapper {
  font-size: 1.25em;
  padding: 0.8rem 0.4rem;
}

.TopMenuElements .UserMenu .menu {
  padding: 1em 1em 1em 0.5em;
}

.TopMenuElements .NotificationMenuWrapper {
  margin: 0 4em 5em 0.6em;
}
`, "",{"version":3,"sources":["webpack://./src/components/top-menu-elements/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".TopMenuElements {\n  display: flex;\n  justify-content: space-between;\n}\n\n.TopMenuElements .NotificationWrapper {\n  font-size: 1.25em;\n  padding: 0.8rem 0.4rem;\n}\n\n.TopMenuElements .UserMenu .menu {\n  padding: 1em 1em 1em 0.5em;\n}\n\n.TopMenuElements .NotificationMenuWrapper {\n  margin: 0 4em 5em 0.6em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
