import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import * as R from 'ramda'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { IStoreState } from '../reducers/types'

export const useTypedSelector: TypedUseSelectorHook<IStoreState> = useSelector

export const useToggleCards = <T = string>(initialOpencards: T[] = []) => {
  const [openCards, setOpenCards] = useState<T[]>(initialOpencards)
  const toggleCard = useCallback(
    (id: T) => () =>
      setOpenCards(
        openCards.includes(id)
          ? R.without([id], openCards)
          : R.append(id, openCards),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openCards, setOpenCards],
  )
  return { openCards, toggleCard, setOpenCards }
}

export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useDeepEffect(() => {
    const timeOut = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => clearTimeout(timeOut)
  }, [delay, value])

  return debouncedValue
}

const useDeepCompareMemoize = (deps) => {
  const ref = useRef()

  if (!R.equals(deps, ref.current)) {
    ref.current = deps
  }

  return ref.current
}

export const useDeepEffect = (
  effectCallback: EffectCallback,
  deps: DependencyList,
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectCallback, useDeepCompareMemoize(deps))
}
