import { Observable, of, withLatestFrom } from 'rxjs'
import { ajax, AjaxRequest } from 'rxjs/ajax'
import { filter, map, mergeMap } from 'rxjs/operators'

import { IStoreState } from '../reducers/types'
import { authSubject } from '../auth/keycloak'
import * as R from 'ramda'
import { StateObservable } from 'redux-observable'

type ReqMethodType = 'GET' | 'POST' | 'DELETE' | 'PUT'

export const getToken = () =>
  mergeMap(() => authSubject.pipe(mergeMap((keycloak) => keycloak.getToken())))

export interface ICommonApi {
  get(
    url: string,
    state: IStoreState,
  ): (obs$: Observable<any>) => Observable<any>
  delete(
    url: string,
    state: IStoreState,
  ): (obs$: Observable<any>) => Observable<any>
  post(
    url: string,
    state: IStoreState,
    data: any,
  ): (obs$: Observable<any>) => Observable<any>
  put(
    url: string,
    state: IStoreState,
    data: any,
  ): (obs$: Observable<any>) => Observable<any>
  default(
    url: string,
    method: ReqMethodType,
    state: IStoreState,
    data?: any,
  ): (obs$: Observable<any>) => Observable<any>
}

const withAuthentication =
  (url: string, body: any, method: ReqMethodType = 'GET') =>
  (obs$: Observable<any>) =>
    obs$.pipe(
      getToken(),
      map((accessToken: string) =>
        ajax({
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/hal+json,application/json',
            'Content-Type': 'application/json',
          },
          method,
          url,
          body,
        }),
      ),
    )

const getAuthTokenHeaderSelector = (state): AjaxRequest =>
  R.pipe(
    getToken(),
    R.concat('Bearer '),
    R.objOf('Authorization'),
    R.objOf('headers'),
  )(state)

export type IAjaxWithAuth = (
  state$: StateObservable<IStoreState>,
) => (obs$: Observable<AjaxRequest>) => any

export const ajaxWithAuth: IAjaxWithAuth = (state$) => (obs$) =>
  obs$.pipe(
    withLatestFrom(state$),
    map(([ajaxObj, state]) => {
      return R.mergeRight(getAuthTokenHeaderSelector(state), ajaxObj)
    }),
    mergeMap(ajax),
  )

export const commonApi: ICommonApi = {
  get: (url, state) => (obs$) =>
    (obs$ || of(state)).pipe(
      withAuthentication(url, undefined),
      mergeMap((resp$) =>
        resp$.pipe(
          filter((resp) => resp.response != null),
          map((resp) => resp.response),
        ),
      ),
    ),

  delete: (url, state) => commonApi.default(url, 'DELETE', state),

  post: (url, state, data) => commonApi.default(url, 'POST', state, data),

  put: (url, state, data) => commonApi.default(url, 'PUT', state, data),

  default:
    (url, method, state, data = undefined) =>
    (obs$) =>
      obs$.pipe(
        withAuthentication(url, data, method),
        mergeMap((resp$) => resp$.pipe(map((resp) => resp))),
      ),
}
