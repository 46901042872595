import { combineEpics, ofType, StateObservable } from 'redux-observable'
import {
  catchError,
  filter,
  mapTo,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators'
import { concat, Observable, of } from 'rxjs'
import { AnyAction } from 'redux'
import { commonRedux } from '@mattilsynet/mt-common'
import { uiActions, uiActionTypes } from '../actions'
import { IStoreState } from '../../../reducers/types'
import { userActions, userActionTypes } from '../../user/actions'
const { isOfType } = commonRedux

export const initialLoadingWhenUserFoundEpic = (
  action$: Observable<AnyAction>,
): Observable<AnyAction> =>
  action$.pipe(ofType(userActionTypes.SET_USER), mapTo(uiActions.initialize()))

export const initialLoadingEpic =
  () =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      filter(isOfType([uiActionTypes.INITIALIZE])),
      withLatestFrom(state$),
      mergeMap(() =>
        concat(
          of(userActions.fetchUserOrgenhet()),
          of(uiActions.initializeSuccess()),
        ).pipe(
          catchError((err) => of(uiActions.initializeFailed(err.message))),
        ),
      ),
    )

export default () =>
  combineEpics(initialLoadingEpic(), initialLoadingWhenUserFoundEpic)
