import { combineEpics, ofType, StateObservable } from 'redux-observable'
import { concat, Observable, of } from 'rxjs'
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'

// actions
import { userActions, userActionTypes } from '../actions'

// API
import { ICommonApi } from '../../../api'

// types
import { AnyAction } from 'redux'
import { IStoreState } from '../../../reducers/types'
import { notifications } from '@mattilsynet/mt-common'
import { API_ROUTES } from '../../../epics'
import { IUserState } from '../types'

export enum APPLIKASJONER {
  MAKKS = 'MAKKS',
  TILSYNSKVITTERING = 'Tilsynskvittering',
  FOTO = 'Fotoløsning',
}

export const getUserOrgenhet =
  (commonApi: ICommonApi) =>
  (
    action$: Observable<AnyAction>,
    state$: StateObservable<IStoreState>,
  ): Observable<AnyAction> =>
    action$.pipe(
      ofType(userActionTypes.FETCH_USER_ORGENHET),
      withLatestFrom(state$),
      switchMap(([, state]) =>
        of(state).pipe(
          commonApi.get(`${API_ROUTES.ORGENHET}/v1/me/orgenhet`, state),
          map((resp) => userActions.fetchUserOrgenhetOk(resp)),
          catchError((err) =>
            of(userActions.fetchUserOrgenhetFail(err.message)),
          ),
        ),
      ),
    )

export const getUserAvdelingEpic =
  (commonApi: ICommonApi) =>
  (
    action$: Observable<AnyAction>,
    state$: StateObservable<IStoreState>,
  ): Observable<AnyAction> =>
    action$.pipe(
      ofType(userActionTypes.FETCH_USER_ORGENHET_OK),
      withLatestFrom(state$),
      switchMap(([{ data }, state]) =>
        of(state).pipe(
          commonApi.get(
            `${API_ROUTES.ORGENHET}/orgenheter/parenttype/Avdeling/id/${(data as IUserState['me']).id}`,
            state,
          ),
          mergeMap((resp) => {
            return concat(
              of(userActions.fetchUserAvdelingOk(resp)),
              of(
                notifications.actions.startPolling(10000, [
                  APPLIKASJONER.TILSYNSKVITTERING,
                ]),
              ),
            )
          }),
          catchError((err) =>
            of(userActions.fetchUserAvdelingFail(err.message)),
          ),
        ),
      ),
    )

export const getUserRegionEpic =
  (commonApi: ICommonApi) =>
  (
    action$: Observable<AnyAction>,
    state$: StateObservable<IStoreState>,
  ): Observable<AnyAction> =>
    action$.pipe(
      ofType(userActionTypes.FETCH_USER_ORGENHET_OK),
      withLatestFrom(state$),
      switchMap(([{ data }, state]) =>
        of(state).pipe(
          commonApi.get(
            `${API_ROUTES.ORGENHET}/orgenheter/parenttype/Region/id/${(data as IUserState['me']).id}`,
            state,
          ),
          map((resp) => userActions.fetchUserRegionOk(resp)),
          catchError((err) => of(userActions.fetchUserRegionFail(err.message))),
        ),
      ),
    )

export default (commonApi: ICommonApi) =>
  combineEpics(
    getUserAvdelingEpic(commonApi),
    getUserRegionEpic(commonApi),
    getUserOrgenhet(commonApi),
  )
