import * as R from 'ramda'
import { IUserState } from './types'
import { STORE_NAME } from './reducer'
import { IStoreState } from '../../reducers/types'

export const reducerLenses = {
  avdeling: () => R.lensPath(['avdeling']),
  me: () => R.lensPath(['me']),
  region: () => R.lensPath(['region']),
}

export const userLenses = R.map(
  (lens) =>
    (...args) =>
      R.compose(R.lensPath([STORE_NAME]), lens(...args)),
  reducerLenses,
)

export const userSelectors = {
  getAvdeling: (state: IStoreState): IUserState['avdeling'] =>
    R.view(userLenses.avdeling())(state),

  getMe: (state: IStoreState): IUserState['me'] =>
    R.view(userLenses.me())(state),

  getRegion: (state: IStoreState): IUserState['region'] =>
    R.view(userLenses.region())(state),

  getAuthUser: (state: IStoreState): IUserState['authUser'] =>
    state.user.authUser,

  getAuthUsername: (state: IStoreState): string | undefined =>
    state?.user?.authUser?.username,
}
