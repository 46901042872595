import { useCallback } from 'react'
import {
  NavigateFunction,
  NavigateOptions,
  To,
  useLocation,
  useNavigate as useReactRouterNavigate,
} from 'react-router-dom'
import { router } from '../routes/router'

export { useTOINavigate, useGoBack, navigate }

const _navigate =
  (navigateFn: NavigateFunction, pathname: string) =>
  (to: To, options?: NavigateOptions) => {
    navigateFn(to, {
      ...options,
      state: { fromPath: pathname, ...options?.state },
    })
  }

const useTOINavigate = () => {
  const navigate = useReactRouterNavigate()
  const { pathname } = useLocation()

  return useCallback(
    (to: To, options?: NavigateOptions) => {
      _navigate(navigate, pathname)(to, options)
    },
    [navigate, pathname],
  )
}

const useGoBack = (fallbackPath = '/') => {
  const navigate = useReactRouterNavigate()
  const {
    state: { fromPath },
  } = useLocation()

  return useCallback(() => {
    if (fromPath) {
      navigate(-1)

      navigate(fallbackPath)
    }
  }, [fromPath, navigate, fallbackPath])
}

/**
 * Navigate to a new route
 * @internal PRIVATE - DO NOT USE. See: https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219
 */
const navigate = (to: To, options?: NavigateOptions) => {
  const { pathname } = router.state.location

  _navigate(router.navigate, pathname)(to, options)
}
